import SessionStatePersister from '@/utils/browser/SessionStatePersister';

export default class TabPersister extends SessionStatePersister {
  setNameSpace(namespace) {
    super.setNameSpace('tabbedEditor_' + namespace);
  }
  getPersistedTab() {
    return super.getPersistedValue('tab');
  }
  persistTab(tab) {
    super.persistValue('tab', tab);
  }
  getCleanupFunc() {
    return super.getCleanupFunc(['tab']);
  }
}
